import LastNameField from "./components/LastNameField";
import NdssNumberField from "./components/NdssNumberField";

function checkForm(formEl, event) {
  if (!formEl) {
    return;
  }

  const lastNameField = new LastNameField();
  const ndssNumberField = new NdssNumberField();

  lastNameField.clearErrors();
  ndssNumberField.clearErrors();

  if (!lastNameField.isValid() || !ndssNumberField.isValid()) {
    if (!lastNameField.isValid()) {
        lastNameField.validateLastName();
        lastNameField.setFocus();
    }
    if (!ndssNumberField.isValid()) {
        ndssNumberField.validateNDSSNumber();
        // Only set focus here is lastname doesn't have focus already
        if(!lastNameField.currentlyHasFocus()) {
            ndssNumberField.setFocus();
        }
    }
    // We stop the form submitting if there are any errors
    event.preventDefault();
  }
}

function addSubmitEventListener(formEl) {
  if (formEl) {
    formEl.addEventListener("submit", function (event) {
      checkForm(formEl, event);
    });
  }
}

const form = document.querySelector(".ndssrv-form");
addSubmitEventListener(form);
