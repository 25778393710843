class FormField {
  constructor(input, errorElement) {
    this.input = input;
    this.errorElement = errorElement;
    this.errorText = "";
  }

  isValid() {
    return this.input.validity.valid;
  }

  clearErrors() {
    this.input.classList.remove("ndssrv-error");
    this.errorElement.textContent = "";
  }

  showError() {
    this.input.classList.add("ndssrv-error");
    this.errorElement.textContent = this.errorText;
  }

  currentlyHasFocus() {
    if (this.input === document.activeElement) {
      return true;
    } else {
      return false;
    }
  }

  setFocus() {
    this.input.focus();
  }

  validate() {}
}

export default FormField;
