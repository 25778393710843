import FormField from "../classes/FormField";

class LastNameField extends FormField {
  constructor() {
    super(
      document.querySelector("#ndss_lastname"),
      document.querySelector("#ndss_lastname + .ndssrv-field__error"),
    );
  }

  validateLastName() {
    if (this.input.validity.valueMissing) {
      this.errorText = "You need to enter your last name.";
      this.showError();
    }
  }
}

export default LastNameField;
