import FormField from "../classes/FormField";

class NDSSNumberField extends FormField {
  constructor() {
    super(
      document.querySelector("#ndss_number"),
      document.querySelector("#ndss_number + .ndssrv-field__error")
    );
  }

  validateNDSSNumber() {
    if (this.input.validity.valueMissing) {
      this.errorText = "You need to enter your NDSS number.";
      this.showError();
    } else if (this.input.validity.patternMismatch) {
      this.errorText = "NDSS number must be numeric.";
      this.showError();
    }
  }
}

export default NDSSNumberField;
